.sidebar {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.plus-icon-wrapper {
  padding: 10px 10px 8px 10px;
  background-color: #3b3b3b;
  border-radius: 6px;
}

.plus-icon {
  width: 30px;
  height: auto;
  color: aliceblue;
}

.sidebar-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  height: 0;
  transition: 300ms;
}

.sidebar-list-active {
  height: 300px;
}

.sidebar-list-item {
  width: 22px;
  height: 30px;
  border-radius: 3px;
  list-style: none;
}
