.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 60px;
  gap: 40px;
  font-family: "Space Grotesk", sans-serif;
}

.header {
  font-size: 2rem;
  font-weight: 600;
  color: aliceblue;
}

.main {
  display: flex;
  gap: 60px;
  height: 100%;
}

.main-note-container {
  flex: 1;
}

/* ADD CUSTOM SCROLL-BAR HERE */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  display: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(134, 133, 133);
  display: block;
}
